body {
  margin: 0;
  font-family: Poppins, sans-serif;
  line-height: 100%;
  height: 100vh;
  overflow-y: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 135%;
}

p {
  line-height: 150%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.srv-validation-message {
  color: #c6492d;
}
