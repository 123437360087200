.starAnimation {
  animation-name: starAnimationOpacity;
  animation-duration: 3s;
}
@keyframes starAnimationOpacity {
  0% {
    opacity: 100%;
  }
  80% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.mavricLogoLoader {
  position: absolute;
  animation: pulsingAnimation 1.5s infinite;
}
@keyframes pulsingAnimation {
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}
.react-pdf__Page__textContent {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}
.react-pdf__Page__annotations {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.MuiSkeleton-root {
  transform: none !important;
}

.vi__character {
  border-color: #787774;
  border-radius: 4px;
}
.vi__character--inactive {
  background-color: #d9d9d8;
}
